import useLocaleCfg from '@/common/hooks/use-locale-cfg';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { env } from '@/env.mjs';
import { useSession } from '@/feat/auth/context';
import { css } from '@emotion/react';
import MagicBell, {
  FloatingNotificationInbox,
  INotification,
  IRemoteNotification,
  NotificationContent,
  NotificationState,
  Popover,
  StyledNotificationContainer,
  toRGBA,
  useNotification,
  useNotificationStoresCollection,
  useTheme,
} from '@magicbell/magicbell-react';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import CheckedIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditIcon from '@mui/icons-material/EditTwoTone';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NotificationTwoToneIcon from '@mui/icons-material/NotificationsTwoTone';
import StarBorderIcon from '@mui/icons-material/StarBorderTwoTone';
import {
  Badge,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme as useMuiTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { intervalToDuration, parse } from 'date-fns';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useInterval } from 'react-use';
import LeftBarButton from './left-bar-button';
import get from 'lodash-es/get';
import { useSetAtom } from 'jotai';
import { reservationDrawerAtom } from '@/feat/reservation/components/global-reservation-drawer';

enum StoreId {
  Booked = 'booked',
  Confirmed = 'confirmed',
  Modified = 'modified',
  Cancelled = 'cancelled',
  DailyBrief = 'daily_brief',
}

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    fontFamily: 'Public Sans !important',
  },
}));

export default function Notifications() {
  const [isActive, setIsActive] = useState(false);
  const { data } = useSession();
  const omsOutletId = data?.outlet?.omsOutletId;
  const { t } = useTranslation();
  const { stores, fetchAllStores, markAllAsRead } =
    useNotificationStoresCollection();
  const router = useRouter();

  const totalUnreadCount = Object.values(stores).reduce((acc, it) => {
    return acc + it.unreadCount;
  }, 0);

  // prefer omsOutletId to userEmail
  const userEmail = data?.userInfo?.email;
  const setReservationDrawerAtom = useSetAtom(reservationDrawerAtom);

  const playSound = (notification: IRemoteNotification) => {
    try {
      if (
        !(
          router.pathname.includes('reservations') &&
          router.query.v === 'timeline' &&
          notification?.category === 'modified'
        )
      ) {
        const audio = new Audio('/audio/notification.mp3');
        audio.play();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotificationClick = (notification: INotification) => {
    const reservationId = get(notification.customAttributes, 'reservation.id');
    if (reservationId) {
      setReservationDrawerAtom({ mode: 'View', reservationId });
    }
    return false;
  };

  useEffect(() => {
    // MagicBell doesn't fetch notification when divide into multi stores
    // Manually fetch in the beginning to show correct badge
    fetchAllStores();
  }, [fetchAllStores]);

  const locale = useLocaleCfg();

  const chineseTranslation = useMemo(() => {
    if (!locale['BCP 47']) {
      return;
    }
    return {
      name: locale['BCP 47'],
      translations: {
        header: {
          title: t('NOTIFICATIONS'),
          'mark-all-read': t('Mark all read'),
        },
        notification: {
          'mark-as-read': t('Mark as read'),
          'mark-as-unread': t('Mark as unread'),
          delete: t('Delete'),
        },
        'web-push-notifications': {
          notice: t(
            'By enabling browser notifications, you’ll stay up to date even better.'
          ),
          'enable-now': t('Enable Now'),
        },
      },
    };
  }, [locale, t]);

  if (!omsOutletId && !userEmail) {
    return null;
  }
  const fontFamily = {
    fontFamily: 'Public Sans',
  };

  return (
    <MagicBell
      apiKey={env.NEXT_PUBLIC_MAGIC_BELL_API_KEY}
      userExternalId={omsOutletId || ''}
      userEmail={userEmail || ''}
      onToggle={(isOpen) => {
        setIsActive(isOpen);
      }}
      onNewNotification={playSound}
      stores={[
        {
          id: StoreId.Booked,
          defaultQueryParams: { categories: [StoreId.Booked] },
        },
        {
          id: StoreId.Confirmed,
          defaultQueryParams: { categories: [StoreId.Confirmed] },
        },
        {
          id: StoreId.Modified,
          defaultQueryParams: { categories: [StoreId.Modified] },
        },
        {
          id: StoreId.Cancelled,
          defaultQueryParams: { categories: [StoreId.Cancelled] },
        },
        {
          id: StoreId.DailyBrief,
          defaultQueryParams: { categories: [StoreId.DailyBrief] },
        },
      ]}
      BellIcon={
        <LeftBarButton
          isActive={isActive}
          icon={
            <StyledBadge
              badgeContent={totalUnreadCount}
              sx={{
                fontSize:
                  totalUnreadCount >= 100
                    ? '10px !important'
                    : '11px !important',
              }}
              color="error"
            >
              <NotificationTwoToneIcon fontSize="medium" />
            </StyledBadge>
          }
          content={
            <Typography
              variant="caption2"
              style={{
                margin: 0,
                fontWeight: 500,
                lineHeight: 1.6,
                fontSize: '0.625rem',
              }}
            >
              <Trans>Notifications</Trans>
            </Typography>
          }
        />
      }
      theme={{
        banner: fontFamily,
        header: fontFamily,
        footer: fontFamily,
        unseenBadge: {
          backgroundColor: '#F80808',
          ...fontFamily,
        },
        container: fontFamily,
        notification: {
          default: {
            textColor: '#3A424D',
            borderRadius: '16px',
            backgroundColor: '#FFFFFF',
            hover: { backgroundColor: '#F2EDFC' },
            state: { color: 'transparent' },
            margin: '8px',
            ...fontFamily,
          },
          unread: {
            textColor: '#3A424D',
            backgroundColor: '#F8F5FF',
            hover: { backgroundColor: '#F2EDFC' },
            state: { color: '#5225C1' },
            ...fontFamily,
          },
          unseen: {
            textColor: '#3A424D',
            backgroundColor: '#F8F5FF',
            hover: { backgroundColor: '#F2EDFC' },
          },
        },
      }}
      locale={chineseTranslation}
    >
      {(props) => (
        <FloatingNotificationInbox
          notificationPreferencesEnabled={false}
          hideArrow={false}
          height={Math.min(window.innerHeight, 540)}
          popperOptions={{ strategy: 'fixed' }}
          tabs={[
            { storeId: StoreId.Booked, label: t('Booked') },
            { storeId: StoreId.Confirmed, label: t('Confirmed') },
            { storeId: StoreId.Modified, label: t('Modified') },
            { storeId: StoreId.Cancelled, label: t('Cancelled') },
            { storeId: StoreId.DailyBrief, label: t('Daily Brief') },
          ]}
          NotificationItem={NotificationItem}
          onAllRead={markAllAsRead}
          onNotificationClick={handleNotificationClick}
          {...props}
        />
      )}
    </MagicBell>
  );
}

interface CustomAttribute {
  action?: 'booked' | 'confirmed' | 'daily_brief' | 'modified' | 'cancelled';
  actor?: 'Customer' | 'Merchant';
  outlet?: {
    id: string;
    outletName: string;
  };
  reservation?: {
    customerFirstName: string;
    customerLastName: string;
    customerPhone: string;
    id: string;
    notes: string;
    occasions: string;
    pax: number;
    reservationDate: string;
    reservationTime: string;
  };
  user?: {
    firstName: string;
    id: string;
    lastName: string;
    salutation: string;
    email: string;
  };
  totalCovers?: number;
  totalOccasions?: number;
  totalReservation?: number;
  totalSpecialRequests?: number;
  totalStaffNotes?: number;
}

const NotificationItem = ({
  notification: rawNotification,
  onClick,
}: {
  notification: IRemoteNotification;
  onClick?: (notification: INotification) => void | boolean;
}) => {
  const {
    notification: { default: theme },
  } = useTheme();
  const notification = useNotification(rawNotification);

  const customAttributes =
    notification.customAttributes as unknown as CustomAttribute;

  const { sentAt } = notification;
  const { formatDate } = useI18nTimeUtils();

  const {
    action,
    actor,
    outlet,
    reservation,
    user,
    totalCovers,
    totalOccasions,
    totalReservation,
    totalSpecialRequests,
    totalStaffNotes,
  } = customAttributes || {};

  const { t } = useTranslation();

  const fullName = user ? `${user.firstName} ${user.lastName}` : '';
  const salutation =
    user && user.salutation && user.salutation !== 'N/A'
      ? `${user.salutation}.`
      : '';
  const displayName = useMemo(
    () =>
      [salutation, user?.firstName, user?.lastName]
        .filter((it) => !!it)
        .join(' '),
    [salutation, user?.firstName, user?.lastName]
  );
  const reservationDateTime = useMemo(
    () =>
      reservation && reservation.reservationDate && reservation.reservationTime
        ? parse(
            `${reservation.reservationDate} ${reservation.reservationTime}`,
            'yyyy-MM-dd HH:mm:ss',
            new Date()
          )
        : null,
    [reservation]
  );
  const reservationDateTimeString = reservationDateTime
    ? formatDate(reservationDateTime, {
        en: 'EEEE, dd MMM yyyy, hh:mma',
        zh: 'EEEE, dd MMM yyyy, hh:mma',
      })
    : '';

  const title = useMemo(() => {
    const outletName = outlet?.outletName;
    switch (action) {
      case 'booked':
        return actor === 'Customer'
          ? t('Reservation booked by {{displayName}}', { displayName })
          : t('Reservation booked by {{outletName}}', { outletName });
      case 'confirmed':
        return actor === 'Customer'
          ? t('Reservation confirmed by {{displayName}}', { displayName })
          : t('Reservation confirmed by {{outletName}}', { outletName });
      case 'modified':
        return actor === 'Customer'
          ? t('Reservation modified by {{displayName}}', { displayName })
          : t('Reservation modified by {{outletName}}', { outletName });
      case 'cancelled':
        return actor === 'Customer'
          ? t('Reservation cancelled by {{displayName}}', { displayName })
          : t('Reservation cancelled by {{outletName}}', { outletName });
      case 'daily_brief':
        return sentAt
          ? t('Daily Brief for {{date}}', {
              date: formatDate(sentAt.unix() * 1000, {
                en: 'E, dd LLL yyyy',
                zh: 'E, dd LLL yyyy',
              }),
            })
          : '';
      default:
        return '';
    }
  }, [action, actor, displayName, formatDate, outlet?.outletName, sentAt, t]);

  const content = useMemo(() => {
    if (action !== 'daily_brief') {
      return `${salutation}${fullName} • ${
        outlet?.outletName
      } • ${reservationDateTimeString} • ${reservation?.pax} ${t('Pax')} • ${
        reservation?.customerPhone
      } • ${user?.email}`;
    } else {
      return t(
        'As of today 08:00am: Total of {{totalReservation}} reservation • Total of {{totalCovers}} covers • {{totalOccasions}} occasions • {{totalSpecialRequests}} special requests • {{totalStaffNotes}} staff notes',
        {
          totalReservation,
          totalCovers,
          totalOccasions,
          totalSpecialRequests,
          totalStaffNotes,
        }
      );
    }
  }, [
    action,
    reservationDateTimeString,
    fullName,
    outlet?.outletName,
    reservation?.customerPhone,
    reservation?.pax,
    salutation,
    t,
    totalCovers,
    totalOccasions,
    totalReservation,
    totalSpecialRequests,
    totalStaffNotes,
    user?.email,
  ]);

  const translatedNotification = useMemo(() => {
    return customAttributes && customAttributes.action
      ? { ...notification, title, content, sanitizedContent: content }
      : notification;
  }, [content, customAttributes, notification, title]);

  const openActionUrl = () => {
    if (notification.actionUrl) window.open(notification.actionUrl, '_self');
  };

  const handleClick = (event: any) => {
    // ignore event when user clicks inside the menu
    if (event.isDefaultPrevented()) return;
    const markAsReadPromise = notification.markAsRead();

    // We don't want to invoke the action url when the user clicks a link or button inside the notification.
    // Notification content should take precedence.
    const isActionableElement = /^(a|button|input)$/i.test(
      event.target.tagName
    );

    if (isActionableElement) return;
    const onClickResult = onClick?.(notification);
    if (onClickResult === false) return;

    if (notification.actionUrl) {
      const timeout = new Promise((resolve) => setTimeout(resolve, 1_000));
      Promise.race([markAsReadPromise, timeout]).then(() => openActionUrl());
    }
  };

  const contentStyle = css`
    margin: 0 8px !important;
    width: 100%;
  `;

  const actionsStyle = css`
    display: flex;
    padding: 0 5px !important;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto !important;
    font-size: ${theme.fontSize} !important;
  `;

  return (
    <StyledNotificationContainer
      role="button"
      aria-labelledby={`magicbell-notification-title-${translatedNotification.id}`}
      notification={translatedNotification}
      onClick={handleClick}
    >
      <NotificationState notification={translatedNotification} />
      <div css={contentStyle}>
        <NotificationTitle notification={translatedNotification} />
        <NotificationContent notification={translatedNotification} />
      </div>
      <div css={actionsStyle}>
        {translatedNotification.sentAt ? (
          <Timestamp date={translatedNotification.sentAt.unix() * 1000} />
        ) : (
          <div />
        )}
        <NotificationMenu notification={translatedNotification} />
      </div>
    </StyledNotificationContainer>
  );
};

function NotificationTitle({ notification }: { notification: INotification }) {
  const { title } = notification;
  const theme = useMuiTheme();

  const {
    notification: {
      default: { title: titleTheme },
    },
  } = useTheme();

  const icon = useMemo(() => {
    switch (notification.category) {
      case 'booked':
        return (
          <CheckedIcon
            fontSize="small"
            sx={{ color: theme.palette.info.main }}
          />
        );
      case 'confirmed':
        return (
          <DoneAllIcon
            fontSize="small"
            sx={{ color: theme.palette.success.main }}
          />
        );
      case 'daily_brief':
        return (
          <StarBorderIcon
            fontSize="small"
            sx={{ color: theme.palette.warning.main }}
          />
        );
      case 'modified':
        return (
          <EditIcon
            fontSize="small"
            sx={{ color: theme.palette.warning.dark }}
          />
        );
      case 'cancelled':
        return (
          <CancelTwoToneIcon
            fontSize="small"
            sx={{ color: theme.palette.grey[600] }}
          />
        );
      default:
        return null;
    }
  }, [notification.category, theme.palette]);

  return (
    <p
      id={`magicbell-notification-title-${notification.id}`}
      css={css`
        cursor: inherit;
        font-weight: ${titleTheme.fontWeight} !important;
        font-family: ${titleTheme.fontFamily} !important;
        font-size: ${titleTheme.fontSize} !important;
        color: inherit !important;
        line-height: 1.2 !important;
        word-break: break-word !important;
      `}
    >
      <Stack flexDirection="row" alignItems="center" gap={1}>
        {icon}
        <Typography variant="subtitle2">{title}</Typography>
      </Stack>
    </p>
  );
}

function NotificationMenu({ notification }: { notification: INotification }) {
  const theme = useTheme();

  const {
    container: containerTheme,
    notification: { default: notificationTheme },
  } = theme;

  const handleDelete = () => {
    notification.delete();
  };

  const handleToggleRead = () => {
    if (notification.isRead) {
      notification.markAsUnread();
    } else {
      notification.markAsRead();
    }
  };
  return (
    <Popover
      launcher={
        <IconButton size="large" onClick={(evt) => evt.stopPropagation()}>
          <MoreHorizIcon fontSize="medium" />
        </IconButton>
      }
      offset={{ skidding: -4, distance: 2 }}
      placement="bottom-end"
      zIndex={1}
      trigger="click"
    >
      {() => (
        <div
          onClick={(e) => e.stopPropagation()}
          css={css`
            background: ${containerTheme.backgroundColor} !important;
            border-radius: 4px !important;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            padding: 4px 0 !important;
            color: ${notificationTheme.textColor} !important;
            font-family: ${notificationTheme.fontFamily} !important;
            font-size: ${notificationTheme.fontSize} !important;
            text-transform: ${notificationTheme.textTransform} !important;
            min-width: 10em;

            button {
              display: block;
              padding: 0.75em 1.25em !important;
              width: 100%;
              text-align: left;
              background-color: ${toRGBA(
                notificationTheme.backgroundColor,
                notificationTheme.backgroundOpacity
              )} !important;

              &:hover {
                background-color: ${toRGBA(
                  notificationTheme.hover.backgroundColor,
                  notificationTheme.hover.backgroundOpacity
                )} !important;
              }

              &:focus {
                outline: none;
              }

              &:focus-visible {
                outline: 2px ${notificationTheme.textColor} solid !important;
              }
            }
          `}
        >
          <button type="button" onClick={handleToggleRead}>
            {notification.isRead ? (
              <Trans>Mark as unread</Trans>
            ) : (
              <Trans>Mark as read</Trans>
            )}
          </button>
          <button type="button" onClick={handleDelete}>
            <Trans>Delete</Trans>
          </button>
        </div>
      )}
    </Popover>
  );
}

function Timestamp({ date }: { date: Date | number }) {
  const { formatDuration, formatDate, compactDuration } = useI18nTimeUtils();
  const [duration, setDuration] = useState<Duration>(
    intervalToDuration({ start: date, end: new Date() })
  );

  useInterval(
    () => setDuration(intervalToDuration({ start: date, end: new Date() })),
    60_000
  );

  const formattedDuration = useMemo(() => {
    return compactDuration(
      duration.days
        ? formatDuration(duration, {
            format: ['days'],
          })
        : duration.hours
        ? formatDuration(duration, {
            format: ['hours'],
          })
        : duration.minutes
        ? formatDuration(duration, {
            format: ['minutes'],
          })
        : duration.seconds
        ? formatDuration(duration, { format: ['seconds'] })
        : formatDuration(duration)
    );
  }, [compactDuration, duration, formatDuration]);

  return (
    <Tooltip
      title={formatDate(date, {
        en: 'dd LLLL yyyy HH:mm aa',
        zh: 'dd LLLL yyyy HH:mm aa',
      })}
      placement="left"
    >
      <div style={{ whiteSpace: 'nowrap' }}>{formattedDuration}</div>
    </Tooltip>
  );
}
