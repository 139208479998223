import React from 'react';
import MenuPopover from '@/minimals.cc/components/MenuPopover';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemProps,
  ListItemText,
  Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import LeftBarButton from '../left-bar-button';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';

function LItem({
  href,
  target,
  children,
  ...props
}: {
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  children: React.ReactNode;
} & ListItemProps) {
  return (
    <ListItem disablePadding {...props}>
      <ListItemButton
        component="a"
        sx={{ borderRadius: 1 }}
        href={href}
        target={target}
      >
        <ListItemText>
          <Typography variant="body2">{children}</Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

declare global {
  interface Window {
    Intercom?: any;
  }
}

export default function SupportPopover() {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const openSupport = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <LeftBarButton
        isActive={openSupport}
        content={t('Support')}
        onClick={handleClick}
        icon={<SupportTwoToneIcon fontSize="medium" />}
      />
      <MenuPopover
        open={openSupport}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        arrow="left-center"
        sx={{
          p: 0,
          width: 'auto',
          left: '100px !important',
        }}
      >
        <List sx={{ padding: '10px 12px' }}>
          <LItem
            id="IntercomDefaultWidget"
            onClick={() => window.Intercom?.('show')}
          >
            <Trans>Send us a message</Trans>
          </LItem>
          <LItem href="https://help.oddle.me/" target="_blank">
            <Trans>Help guide</Trans>
          </LItem>
        </List>
      </MenuPopover>
    </>
  );
}
