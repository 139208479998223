import { useIsMockImpersonating } from '@/common/hooks/storage';
import { useSession } from '../auth/context';

export const IMPERSONATION_BANNER_HEIGHT = 50;

export default function useImpersonation() {
  const { data: sessionData } = useSession();
  const [isMockImpersonating] = useIsMockImpersonating();
  const accountName = sessionData?.userInfo?.accountName || '';
  const isImpersonating = Boolean(
    isMockImpersonating || sessionData?.userInfo?.isImpersonation
  );
  const bannerHeight = isImpersonating ? IMPERSONATION_BANNER_HEIGHT : 0;
  return {
    accountName,
    bannerHeight,
    bannerHeightConst: IMPERSONATION_BANNER_HEIGHT,
    isImpersonating,
  };
}
