import {
  alpha,
  Button,
  ButtonProps,
  Stack,
  Theme,
  useTheme,
} from '@mui/material';
import { Typography } from '@mui/material';
import styles from './index.module.scss';
import NextLink from '@/common/components/next-link';
import clsx from 'clsx';

interface IProps {
  href?: string;
  isActive?: boolean;
  content: React.ReactNode;
  icon: React.ReactNode;
}

const LeftBarButton = ({
  content,
  icon,
  href,
  isActive,
  className,
  ...rest
}: IProps & Omit<ButtonProps, keyof IProps>) => {
  const theme = useTheme();

  const getBackgroundColor = (
    theme: Theme,
    isHover: boolean = false
  ): string => {
    if (!isHover) {
      if (isActive) return alpha(theme.palette.primary.main, 0.08);
      return 'transparent';
    }
    if (isActive) return alpha(theme.palette.primary.main, 0.08);
    return theme.palette.grey[100];
  };

  const getTextColor = (theme: Theme, isHover: boolean = false): string => {
    if (!isHover) {
      if (isActive) return theme.palette.primary.main;
      return theme.palette.grey[600];
    }
    if (isActive) return theme.palette.primary.main;
    return theme.palette.grey[900];
  };

  return (
    <Button
      LinkComponent={NextLink}
      href={href}
      className={clsx(styles.LeftSidebarItemBtn, className)}
      sx={{
        backgroundColor: `${getBackgroundColor(theme)} !important`,
        color: `${getTextColor(theme)} !important`,
        padding: '7px 0',
        height: '58px',
        '&:hover': {
          backgroundColor: getBackgroundColor(theme, true),
          color: getTextColor(theme, true),
        },
      }}
      {...rest}
    >
      <Stack spacing={0} alignItems="center" justifyContent="center" flex="1">
        {icon}
        <Typography variant="caption2">{content}</Typography>
      </Stack>
    </Button>
  );
};

export default LeftBarButton;
