import { Box, Typography, useTheme } from '@mui/material';
import useImpersonation from './use-impersonation';

const BannerImpersonation = () => {
  const { accountName, isImpersonating, bannerHeightConst } = useImpersonation();
  const theme = useTheme();

  if (!isImpersonating) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: `${bannerHeightConst}px`,
        padding: '12px 50px 12px 40px',
        background: '#3D0099CC',
        color: '#fff',
        position: 'fixed',
        zIndex: theme.zIndex.appBar,
      }}
    >
      <Typography variant="body1">Impersonating as {accountName}</Typography>
    </Box>
  );
};

export default BannerImpersonation;
