import React from 'react';

const LogoOddle: React.FC<{
  width?: number;
  height?: number;
  className?: string;
}> = ({ width = 40, height = 40, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0642 19.2952C23.1244 20.3555 24.8421 20.3555 25.9023 19.2952C26.9626 18.2349 26.9626 16.5173 25.9023 15.457C24.8421 14.3968 23.1244 14.3968 22.0642 15.457C21.0039 16.5173 21.0039 18.2349 22.0642 19.2952Z"
        fill="#3D0099"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.803 29.4102L29.9102 23.303C33.1758 20.0374 33.1758 14.7148 29.9102 11.4492C26.6445 8.18359 21.322 8.18359 18.0564 11.4492L11.9492 17.5564C8.68359 20.822 8.68359 26.1445 11.9492 29.4102C15.2148 32.6758 20.5374 32.6758 23.803 29.4102ZM19.774 13.1669C22.1066 10.8343 25.8811 10.8343 28.2137 13.1669C30.5251 15.4994 30.5251 19.274 28.2137 21.6066C25.8811 23.9392 22.1066 23.9392 19.774 21.6066C17.4414 19.274 17.4414 15.4994 19.774 13.1669Z"
        fill="#3D0099"
      />
    </svg>
  );
};

export default LogoOddle;
