import NextLink from '@/common/components/next-link';
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import { css } from '@emotion/react';
import AddIcon from '../../assets/add-reserve-svg';
import { useSetAtom } from 'jotai';
import { reservationDrawerAtom } from '@/feat/reservation/components/global-reservation-drawer';

export const BOTTOM_NAV_HEIGHT = 72;
export const MobileBottomNav = () => {
  const router = useRouter();
  const { breakpoints } = useTheme();
  const isActive = (href: string) => router.pathname.startsWith(href);

  const setReservationDrawer = useSetAtom(reservationDrawerAtom);

  const handleAddReservation = () => {
    setReservationDrawer({
      mode: 'Add',
    });
  };

  return (
    <BottomNavigation
      component={Paper}
      elevation={4}
      style={{ height: BOTTOM_NAV_HEIGHT }}
      css={css`
        padding-top: 0;
        width: 100%;
        position: fixed;
        bottom: 0;
        border-top: 2px solid #919eab24;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        .MuiBottomNavigationAction-label {
          font-size: 14px;
        }
        .MuiSvgIcon-root {
          font-size: 28px;
        }
        ${breakpoints.up('md')} {
          display: none;
        }
      `}
      showLabels
    >
      <BottomNavigationAction
        LinkComponent={NextLink}
        className={clsx(isActive('/reservations') && 'Mui-selected')}
        href="/reservations"
        label={<Trans>Reservations</Trans>}
        icon={<HomeTwoToneIcon />}
      />

      <BottomNavigationAction
        onClick={handleAddReservation}
        icon={<AddIcon />}
      />
      <BottomNavigationAction
        LinkComponent={NextLink}
        className={clsx(isActive('/settings') && 'Mui-selected')}
        href="/settings"
        label={<Trans>Settings</Trans>}
        icon={<SettingsTwoToneIcon />}
      />
    </BottomNavigation>
  );
};
