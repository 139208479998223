import React from 'react';
const AddIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="34" cy="34" r="34" fill="url(#paint0_linear_880_21374)" />
      <path
        d="M44.5824 32.04V35.04L24.3984 35.064V32.088L44.5824 32.04ZM36.0384 43.632H33.0384L33.0144 23.448H35.9904L36.0384 43.632Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_880_21374"
          x1="-34"
          y1="34"
          x2="34"
          y2="102"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#AFA7E3" />
          <stop offset="1" stop-color="#3D0099" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default AddIcon;
