import LogoOddle from '@/common/assets/logo-oddle';
import ErrorBoundaryWithLogs from '@/common/components/error-boundary';
import { useFeatureFlags } from '@/common/hooks/use-feature-flag';
import useIsAnimating from '@/common/hooks/use-is-animating';
import { useIsoLayoutEffect } from '@/common/utils';
import BannerImpersonation from '@/feat/impersonation/banner';
import useImpersonation from '@/feat/impersonation/use-impersonation';
import { css, SerializedStyles } from '@emotion/react';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import {
  ButtonProps,
  Divider,
  Drawer,
  PaperProps,
  Stack,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import styles from './index.module.scss';
import LeftBarButton from './left-bar-button';
import { BOTTOM_NAV_HEIGHT, MobileBottomNav } from './mobile-bottom-nav';
import { MobileTopNavBase, useMobileTopNav } from './mobile-top-nav';
import Notifications from './notifications';
import SupportPopover from './support-popover';
import { EntitlementBanner } from '@/feat/entitlement-banner';

export type LeftSideBarCtx = {
  setMainBarEl: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  setMainBarWidth: React.Dispatch<React.SetStateAction<number>>;
};

export interface LeftSideBarItemProps extends ButtonProps {
  text?: string | React.ReactNode;
  href?: string;
  icon?: React.ReactNode;
  button?: React.ReactNode;
}

export const LEFT_SIDEBAR_TOP_ITEMS: LeftSideBarItemProps[] = [
  {
    text: <Trans>Home</Trans>,
    href: '/reservations',
    icon: <HomeTwoToneIcon fontSize="medium" />,
  },
  {
    text: <Trans>Customers</Trans>,
    href: '/customers',
    icon: <AccountBoxTwoToneIcon fontSize="medium" />,
  },
  {
    text: <Trans>Transactions</Trans>,
    href: '/transactions',
    icon: <PaidTwoToneIcon fontSize="medium" />,
  },
];

export const LEFT_SIDEBAR_BOTTOM_ITEMS: LeftSideBarItemProps[] = [
  {
    text: <Trans>Notifications</Trans>,
    button: <Notifications />,
  },
  {
    text: <Trans>Support</Trans>,
    button: <SupportPopover />,
  },
  {
    text: <Trans>Settings</Trans>,
    href: '/settings',
    icon: <SettingsTwoToneIcon fontSize="medium" />,
  },
];

export const useLeftSideBarBottomItems = () => {
  const { isMagicBellEnabled } = useFeatureFlags();
  return [
    isMagicBellEnabled && {
      text: <Trans>Notifications</Trans>,
      button: <Notifications />,
    },
    {
      text: <Trans>Support</Trans>,
      button: <SupportPopover />,
    },
    {
      text: <Trans>Settings</Trans>,
      href: '/settings',
      icon: <SettingsTwoToneIcon fontSize="medium" />,
    },
  ].filter(Boolean);
};

const useLeftSideBarCtx = () => {
  const ctx = React.useContext(LeftSideBarContext);
  if (!ctx) throw Error('LeftSideBarProvider not found');
  return ctx;
};

export function LeftSideBarMainNav({
  children,
  width = 272,
}: {
  children: React.ReactNode;
  width?: number;
}) {
  const { setMainBarEl, setMainBarWidth } = useLeftSideBarCtx();

  useIsoLayoutEffect(() => {
    setMainBarWidth(width);
  }, [width]);

  useIsoLayoutEffect(() => {
    setMainBarEl(children);
    return () => setMainBarEl(null);
  }, [children]);

  return null;
}

export const LeftSideBarContext = React.createContext<LeftSideBarCtx | null>(
  null
);

export default function LeftSideBar({
  children,
  drawerCss,
  mainCss,
  contentCss,
}: {
  children: React.ReactNode;
  drawerCss?: SerializedStyles;
  mainCss?: SerializedStyles;
  contentCss?: SerializedStyles;
}) {
  const { breakpoints } = useTheme();
  const miniBarWidth = 80;
  const [mainBarWidth, setMainBarWidth] = React.useState(0);
  const [mainBarEl, setMainBarEl] = React.useState<React.ReactNode>(null);

  const { transitions } = useTheme();
  const ctx = React.useMemo(() => ({ setMainBarEl, setMainBarWidth }), []);
  const open = !!mainBarEl;
  const isAnimating = useIsAnimating(open);
  const { bannerHeight: impersonationBannerHeight } = useImpersonation();
  const { mobileTopNavPlaceholderHeight } = useMobileTopNav();
  const topHeight = mobileTopNavPlaceholderHeight + impersonationBannerHeight;

  const createTransition = React.useCallback(
    (type: string | string[]) =>
      transitions.create(type, {
        easing: transitions.easing.sharp,
        duration: transitions.duration.short,
      }),
    [transitions]
  );

  return (
    <LeftSideBarContext.Provider value={ctx}>
      <ErrorBoundaryWithLogs>
        <BannerImpersonation />
        <MobileTopNavBase />
        <Drawer
          variant="permanent"
          PaperProps={{ component: 'nav', elevation: 16 } as PaperProps}
          className={clsx(
            'LeftSideBar-root',
            open && 'LeftSideBar-open',
            isAnimating && 'LeftSideBar-animating'
          )}
          css={css`
            .MuiPaper-root {
              flex-direction: row;
            }
            .MuiPaper-root.MuiDrawer-paper {
              top: ${topHeight}px;
              height: calc(100% - ${topHeight}px);
              // fix Safari overflow auto not working as expected
              overflow-y: visible;
            }
            ${breakpoints.down('md')} {
              display: none;
            }
            ${drawerCss}
          `}
        >
          <Stack
            width={miniBarWidth}
            padding="16px 8px 16px"
            spacing={2}
            direction="column"
            divider={<Divider orientation="horizontal" flexItem />}
            sx={{
              background: 'white',
              zIndex: 1,
            }}
          >
            <div className={styles['LeftSideBar-logo']}>
              <LogoOddle />
            </div>
            <ErrorBoundaryWithLogs>
              <LeftSidebarNavigation />
            </ErrorBoundaryWithLogs>
          </Stack>
          <Divider orientation="vertical" />
          <ErrorBoundaryWithLogs>
            <div
              css={css`
                width: ${open ? mainBarWidth : 0}px;
                transition: ${createTransition('width')};
                overflow-x: ${!isAnimating ? 'visible' : 'hidden'};
              `}
            >
              <div
                css={css`
                  width: ${mainBarWidth}px;
                  transition: ${createTransition('transform')};
                  height: 100%;
                  overflow-y: auto;
                  transform: translateX(-${mainBarWidth}px);

                  .LeftSideBar-open & {
                    transform: translateX(0);
                  }
                `}
              >
                {mainBarEl}
              </div>
            </div>
          </ErrorBoundaryWithLogs>
        </Drawer>
      </ErrorBoundaryWithLogs>
      <main
        css={css`
          margin-left: ${open ? miniBarWidth + mainBarWidth : miniBarWidth}px;
          transition: ${createTransition('margin-left')};
          display: flex;
          flex-direction: column;
          min-height: 100%;
          padding-top: ${topHeight}px;
          ${breakpoints.down('md')} {
            margin-left: 0;
            background: white;
            padding-bottom: ${BOTTOM_NAV_HEIGHT}px;
          }
          ${breakpoints.up('md')} {
            ${mainCss}
          }
        `}
      >
        <ErrorBoundaryWithLogs>
          <div
            css={css`
              padding: 16px;
              max-width: 1560px;
              margin: auto;
              width: 100%;
              flex: 1;
              display: flex;
              flex-direction: column;
              position: relative;
              min-height: 100%;
              ${breakpoints.down('md')} {
                padding: 16px;
              }
              ${breakpoints.up('md')} {
                ${contentCss}
              }
            `}
          >
            {children}
          </div>
        </ErrorBoundaryWithLogs>
      </main>
      <ErrorBoundaryWithLogs>
        <MobileBottomNav />
      </ErrorBoundaryWithLogs>
    </LeftSideBarContext.Provider>
  );
}

function LeftSidebarNavigation() {
  const itemSpacing = 0.5;
  const leftSideBarBottomItems = useLeftSideBarBottomItems();

  return (
    <Stack spacing={0} justifyContent="space-between" flex="1">
      <Stack spacing={itemSpacing}>
        {LEFT_SIDEBAR_TOP_ITEMS.map((item, i) => (
          <LeftSidebarItem {...item} key={i} />
        ))}
      </Stack>
      <Stack spacing={itemSpacing}>
        {leftSideBarBottomItems.map((item, i) => (
          <LeftSidebarItem {...item} key={i} />
        ))}
      </Stack>
    </Stack>
  );
}

const LeftSidebarItem = (props: LeftSideBarItemProps) => {
  const { href, text, icon, button } = props;
  const router = useRouter();
  const isActive = (() => {
    if (!href) return false;
    if (router.pathname.startsWith(href)) {
      return true;
    }
    return false;
  })();

  if (button) return <>{button}</>;

  return (
    <LeftBarButton href={href} isActive={isActive} icon={icon} content={text} />
  );
};
