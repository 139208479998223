import ResizeObserver from 'resize-observer-polyfill';
import { Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { css } from '@emotion/react';
import useImpersonation from '@/feat/impersonation/use-impersonation';
import { atom, useAtom, useSetAtom } from 'jotai';
import { createTeleporter } from 'react-teleporter';

const mobileTopNavHeightAtom = atom(0);

export const MobileTopNavSlot1 = createTeleporter();
export const MobileTopNavSlot2 = createTeleporter();
export const MobileTopNavSlot3 = createTeleporter();

const useIsHidden = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  return !isMobile;
};

export const useMobileTopNav = () => {
  const [mobileTopNavHeight] = useAtom(mobileTopNavHeightAtom);
  const isHidden = useIsHidden();
  const mobileTopNavPlaceholderHeight = isHidden ? 0 : mobileTopNavHeight;
  return {
    mobileTopNavPlaceholderHeight,
    mobileTopNavHeight,
  };
};

export const MobileTopNavBase = () => {
  const { zIndex } = useTheme();
  const setMobileTopNavHeight = useSetAtom(mobileTopNavHeightAtom);
  const { bannerHeight: impersonationBannerHeight } = useImpersonation();
  const isHidden = useIsHidden();

  return (
    <Toolbar
      ref={(ref) => {
        if (!ref) return;
        new ResizeObserver(() =>
          setMobileTopNavHeight(ref?.getBoundingClientRect().height || 0)
        ).observe(ref);
      }}
      disableGutters
      style={{
        minHeight: 0,
        zIndex: zIndex.appBar,
        top: impersonationBannerHeight,
        display: isHidden ? 'none' : undefined,
      }}
      css={css`
        position: fixed;
        height: auto;
        width: 100%;
        background: white;
        padding: 0px 16px;
        flex-direction: column;
        align-items: stretch;
      `}
    >
      <MobileTopNavSlot1.Target />
      <MobileTopNavSlot2.Target />
      <MobileTopNavSlot3.Target />
    </Toolbar>
  );
};
